export default [
    {
        id: 1,
        name: "Mahatma Gandhi Kashi Vidyapith",
        major: "Commerce",
        degree: "Master's",
        dated: "2015 - 2017",
        cgpa: "8"
    },
];
