export default [
    {
        id: 1,
        name: "English"
    },
    {
        id: 2,
        name: "Hindi"
    }
];
