export default [
    {
        id: 1,
        name: "Yoga"
    },
    {
        id: 2,
        name: "Listening Music"
    },
    {
        id: 3,
        name: "Watching Web-Series"
    },
    {
        id: 4,
        name: "Traveling"
    }
];
